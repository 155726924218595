import { Route, Routes } from 'react-router-dom';
import { PageLanding } from './components/pages/PageLanding';
import { PageRegistration } from './components/pages/PageRegistration';
import { PageRegSuccess } from './components/pages/PageRegSuccess';

function App() {
  return (
    <Routes>
      <Route path="/" element={<PageLanding />} />
      <Route path="/register" element={<PageRegistration />} />
      <Route path="/success-apply" element={<PageRegSuccess />} />
    </Routes>
  );
}

export default App;
